<template>
    <div class="page page-standard page-mention-legale">
        <div class="page-section-col">
            <div class="page-ml-content">
                <h1 style="display: none;">HARMONY</h1>
                <h2 class="title-h2">Mentions légales</h2>
                <div class="page-ml-item">
                    <h4 class="title-h4">Viverra mauris convallis aliquam adipiscing in. Eu blandit purus cras a tincidunt. Quis elementum.</h4>
                    <p>
                        Aliquet et diam ac ultrices molestie etiam massa egestas iaculis. Vitae sed amet at mauris neque porttitor nunc quis. 
                        Velit viverra vitae facilisi non bibendum at diam. Sed duis scelerisque amet molestie. Tincidunt rhoncus proin ut sodales. 
                        Purus commodo ac vitae porttitor lorem. Sit amet enim leo vitae vel malesuada lectus massa mattis.
                    </p>
                    <p>
                        Euismod erat aliquet consectetur vehicula et. Pretium, consequat scelerisque odio enim est tristique enim. Lacus non nec blandit mattis. 
                        Morbi egestas consectetur lacus donec lorem a porttitor. Sit nulla interdum rhoncus, vel risus facilisi nulla. Justo sed sed nec porta. 
                        Odio posuere nisl sapien, arcu gravida quis malesuada integer.
                    </p>
                    <p>
                        Facilisis amet justo hac purus urna eget posuere nulla. Lacinia ultricies at velit porta risus. 
                        Proin integer elit magnis quam. Sapien phasellus neque feugiat quisque commodo ornare sollicitudin. 
                        Purus eget proin id erat aliquet tempus neque integer in. Id id nec, in egestas volutpat cursus ipsum, vitae. 
                        Massa faucibus ut pellentesque congue eget tincidunt massa. Vestibulum, suspendisse pellentesque nisl justo, dis. 
                        Lorem auctor at sed neque quam id iaculis nulla. Ullamcorper faucibus neque sapien, ut. Sagittis tellus blandit posuere nunc enim.
                    </p>
                </div>

                <div class="page-ml-item">
                    <h4 class="title-h4">Ornare nulla blandit praesent lobortis netus.</h4>
                    <p>
                        Mauris varius morbi eget non facilisi in ultricies nulla. Vitae, et quisque tortor elementum venenatis dignissim nunc faucibus lorem. 
                        Turpis vitae commodo cras sagittis. Euismod vitae enim maecenas quam sit sit pellentesque fringilla est. Tellus varius aliquet aliquet velit. 
                        Mattis sit erat feugiat cursus auctor augue. Ac ultrices commodo commodo iaculis id donec mus tristique donec. Sed amet dui quam facilisis iaculis 
                        vehicula pellentesque. Semper ipsum sed elementum fusce morbi. Nunc maecenas erat fames blandit consequat tortor, erat malesuada. 
                        Quisque pellentesque nam laoreet at.
                    </p>
                    <ul>
                        <li>Vitae senectus sit pharetra tellus commodo gravida adipiscing orci.</li>
                        <li>Ac nunc tincidunt netus id convallis. Diam egestas molestie porta felis.</li>
                        <li>Risus neque sed id tortor consequat enim odio ultrices a. </li>
                        <li>Habitant venenatis sed interdum urna, massa eget placerat suspendisse risus.</li>
                    </ul>
                    <p>
                        At bibendum adipiscing tincidunt ac placerat. Ut phasellus rhoncus, felis nisl porta sit consequat elit non. 
                        At aliquet donec quis eget id ut. Mattis convallis donec ultrices molestie aliquet mi pulvinar etiam. Massa nibh maecenas nulla 
                        cursus cursus commodo nunc odio. Sem bibendum sit quisque non, sit ac. Feugiat dui sed viverra egestas proin. Hac dolor elementum 
                        nulla molestie pellentesque. Viverra dictum pellentesque lectus gravida in dignissim lorem orci. Ultrices sit in consectetur metus, 
                        facilisi. Ultrices eget scelerisque eu egestas vel nisi sed sit. Ut nullam iaculis odio purus enim nisl, donec. Mi massa et, 
                        cursus venenatis, nunc, vel auctor.
                    </p>
                </div>

                <div class="page-ml-item">
                    <h4 class="title-h4">Viverra mauris convallis aliquam adipiscing in. Eu blandit purus cras a tincidunt. Quis elementum.</h4>
                    <p>
                        Aliquet et diam ac ultrices molestie etiam massa egestas iaculis. Vitae sed amet at mauris neque porttitor nunc quis. 
                        Velit viverra vitae facilisi non bibendum at diam. Sed duis scelerisque amet molestie. Tincidunt rhoncus proin ut sodales. 
                        Purus commodo ac vitae porttitor lorem. Sit amet enim leo vitae vel malesuada lectus massa mattis.
                    </p>
                    <p>
                        Euismod erat aliquet consectetur vehicula et. Pretium, consequat scelerisque odio enim est tristique enim. Lacus non nec blandit mattis. 
                        Morbi egestas consectetur lacus donec lorem a porttitor. Sit nulla interdum rhoncus, vel risus facilisi nulla. Justo sed sed nec porta. 
                        Odio posuere nisl sapien, arcu gravida quis malesuada integer.
                    </p>
                    <p>
                        Facilisis amet justo hac purus urna eget posuere nulla. Lacinia ultricies at velit porta risus. 
                        Proin integer elit magnis quam. Sapien phasellus neque feugiat quisque commodo ornare sollicitudin. 
                        Purus eget proin id erat aliquet tempus neque integer in. Id id nec, in egestas volutpat cursus ipsum, vitae. 
                        Massa faucibus ut pellentesque congue eget tincidunt massa. Vestibulum, suspendisse pellentesque nisl justo, dis. 
                        Lorem auctor at sed neque quam id iaculis nulla. Ullamcorper faucibus neque sapien, ut. Sagittis tellus blandit posuere nunc enim.
                    </p>
                </div>

                <div class="page-ml-item">
                    <h4 class="title-h4">Ornare nulla blandit praesent lobortis netus.</h4>
                    <p>
                        Mauris varius morbi eget non facilisi in ultricies nulla. Vitae, et quisque tortor elementum venenatis dignissim nunc faucibus lorem. 
                        Turpis vitae commodo cras sagittis. Euismod vitae enim maecenas quam sit sit pellentesque fringilla est. Tellus varius aliquet aliquet velit. 
                        Mattis sit erat feugiat cursus auctor augue. Ac ultrices commodo commodo iaculis id donec mus tristique donec. Sed amet dui quam facilisis iaculis 
                        vehicula pellentesque. Semper ipsum sed elementum fusce morbi. Nunc maecenas erat fames blandit consequat tortor, erat malesuada. 
                        Quisque pellentesque nam laoreet at.
                    </p>
                    <ul>
                        <li>Vitae senectus sit pharetra tellus commodo gravida adipiscing orci.</li>
                        <li>Ac nunc tincidunt netus id convallis. Diam egestas molestie porta felis.</li>
                        <li>Risus neque sed id tortor consequat enim odio ultrices a. </li>
                        <li>Habitant venenatis sed interdum urna, massa eget placerat suspendisse risus.</li>
                    </ul>
                    <p>
                        At bibendum adipiscing tincidunt ac placerat. Ut phasellus rhoncus, felis nisl porta sit consequat elit non. 
                        At aliquet donec quis eget id ut. Mattis convallis donec ultrices molestie aliquet mi pulvinar etiam. Massa nibh maecenas nulla 
                        cursus cursus commodo nunc odio. Sem bibendum sit quisque non, sit ac. Feugiat dui sed viverra egestas proin. Hac dolor elementum 
                        nulla molestie pellentesque. Viverra dictum pellentesque lectus gravida in dignissim lorem orci. Ultrices sit in consectetur metus, 
                        facilisi. Ultrices eget scelerisque eu egestas vel nisi sed sit. Ut nullam iaculis odio purus enim nisl, donec. Mi massa et, 
                        cursus venenatis, nunc, vel auctor.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>    
<script>
export default {
    name: 'MentionsLegales',
    props: {
        msg: String,
    },
}
</script>